@value tokens: "../../../../styles/tokens.css";
@value white, green, coolGray, shadow, border-radius from tokens;

.modal {
  box-shadow: shadow;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: border-radius;
}

.close_icon {
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  fill: white;
  background-color: green;
  border-radius: 50%;
  padding: 0.5rem;
}

@media (max-width: 768px) {
  .preview img {
    max-width: 100%;
  }
}
