
/* Re-write dependency style */
.Dropdown-root {
  min-width: 150px;
}

.Dropdown-control {
  background-color: transparent;
  border: none;

}
.Dropdown-control:hover {
  box-shadow: none;
}

.Dropdown-arrow {
  top: 16px;
  border-color: #303030 transparent transparent;
}

.is-open .Dropdown-arrow {
  border-color: transparent transparent #303030;
}

.Dropdown-menu {
  border: none;
  border-radius: 0.5em;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.Dropdown-option {
  padding: 12px 12px;
}
.Dropdown-option:hover {
  background-color: #DAF5DF;
}
.Dropdown-option.is-selected {
  background-color: #49D060;
  color: #ffffff;
}
