.app {
  position: relative;
  height: 85vh;
}

@media (max-width: 768px) {
  .header {
    display: flex;
    justify-content: center;
  }

  .header > div {
    width: auto;
  }
}
