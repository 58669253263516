.title {
 display: block;
 font-size: 1.8em;
 font-weight: bold;
 letter-spacing: calc(-1em / 32);
 margin: 0.5em 0;
}

.small {
  font-size: 1.1em;
}

.thin {
  font-weight: normal;
}
