@value tokens: "../../styles/tokens.css";
@value green, lightgray from tokens;

.toggle {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 30px;
}

.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: lightgray;
  transition: .2s;
  border-radius: 30px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .2s;
  border-radius: 50%;

}

input:checked + .slider {
  background-color: green;
}

input:checked + .slider:before {
  transform: translateX(25px);
}