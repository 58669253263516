@value tokens: '../../styles/tokens.css';
@value white, green, darkgreen, blue, red, darkred from tokens;

.button {
  display: inline-flex;
  align-items: center;
  height: 2.2em;
  color: white;
  font-weight: bold;
  background-color: green;
  border-radius: 1.1em;
  padding: 0 1.6em;
  user-select: none;
  cursor: pointer;
}

.button:hover {
  background-color: darkgreen;
}

.button:active {
  background-color: green;
}

.ghost {
  background-color: transparent;
  color: blue;
  padding-left: 0;
  padding-right: 0;
}

.ghost:hover,
.ghost:active {
  background-color: transparent;
}

.warning {
  background-color: red;
}

.warning:hover {
  background-color: darkred;
}

.warning:active {
  background-color: red;
}

.disabled {
  opacity: 0.5;
  cursor: default;
}
