@value tokens: "../../styles/tokens.css";
@value black, white, shadow, border-radius-large from tokens;

.banner {
  background-color: white;
  border-radius: border-radius-large;
  box-shadow: shadow;
}

.image {
  width: 100%;
  height: auto;
  border-radius: border-radius-large border-radius-large 0 0;
}

.content {
  max-width: 450px;
  padding: 0 2em 2em;
}
