@value common: "../../styles/common.css";
@value tokens: "../../styles/tokens.css";

@value transition from tokens;
@value flex_middle from common;

.hero {
  transform: none;
  transition: transform transition;
  will-change: transform;

  user-select: none;
  pointer-events: none;
}


.hero a {
    pointer-events: auto;
}

.hidden {
  transform: translate(0px, 50vh);
}
