@value common: "../../styles/common.css";
@value flex_middle from common;

.icons {
  composes: flex_middle;
  flex-wrap: wrap;
  margin: 2em 0;
}

.icon {
  display: inline-block;
  margin: 0 1em;
}