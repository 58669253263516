@value tokens: "../../../../styles/tokens.css";
@value white, shadow, border-radius from tokens;

.modal {
  padding: 2em;
  background-color: white;
  border-radius: border-radius;
  box-shadow: shadow;
  width: 400px;
}

/* Title */
.modal span {
  margin: 0;
}

.content {
  margin: 2em 0;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.buttons span {
  margin-left: 2em;
}

@media (max-width: 576px) { 
  .modal {
    width: auto;
  }

  .buttons {
    justify-content: space-between;
  }

  .buttons span {
    margin-left: 0;
  }
}