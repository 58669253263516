@value tokens: "../../styles/tokens.css";
@value lightgray, gray, red from tokens;

.textarea {
  border: none;
  border-radius: 0.5em;
  line-height: 1.25;
  outline: none;
  padding: 0.5em;
  width: 100%;
}

.textarea::placeholder {
  color: gray;
}

.ghost {
  background-color: transparent;
  border-bottom: 1px solid lightgray;
  border-radius: 0;
}

.ghost_error {
  border-color: red;
}

.error::placeholder {
  color: red;
}

