@value tokens: "../../styles/tokens.css";
@value transition from tokens;

.main {
  transform: translate(0px, -25vh);
  transition: transform transition;
  will-change: transform;
}

.secondary {
  transform: translateY(0);
  transition: none;
  min-height: 100%;
}

.hidden {
  transform: none;
}