@value common: "../../styles/common.css";
@value tokens: '../../styles/tokens.css';
@value red from tokens;
@value box from common;

.container {
  composes: box;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message {
  text-align: center;
}

.subtitle {
  text-transform: uppercase;
  font-size: 0.875em;
  font-weight: bold;
  margin: 48px 0 24px;
}

.errorMessage {
  color: red;
}

.input {
  margin-bottom: 12px;
}

.button {
  margin: 12px;
}

.facebookButton {
  background-color: #3b5998;
  margin: 0;
}

.facebookButton:hover {
  background-color: #3b5998;
}

.facebookButton:active {
  background-color: #3b5998;
}

