@value tokens: "../../styles/tokens.css";
@value gray from tokens;

.timestamp{
  color: gray;
  white-space: nowrap;
}

.small {
  font-size: 0.875em;
}
