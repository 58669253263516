@value tokens: "../../styles/tokens.css";
@value green, red from tokens;

@value common: "../../styles/common.css";
@value box, flex_middle from common;

.hero__shadow {
  box-shadow: 0 0 2.5em rgba(0, 0, 0, 0.4);
}

.grid {
  composes: box;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2em;
  margin-top: 1em;
}

.settings,
.feedback,
.faq {
  margin-bottom: 3em;
}

.faq__buttons,
.feedback__buttons {
  margin: 1em 0;
  margin-bottom: 3em;
  text-align: right;
}

.feedback__buttons {
  composes: flex_middle;
  justify-content: space-between;
}

.feedback__form input {
  margin-bottom: 1em;
}

.error {
  color: red;
  margin-right: 0.5em;
  text-align: left;
}

.success {
  font-weight: bold;
  color: green;
}

.social {
  grid-column: 1 / -1;
}

@media (max-width: 768px) {
  .content,
  .banner {
    grid-column: 1 / -1;
  }

  .hero__cta_button {
    display: none;
  }
}
