@value tokens: "../../styles/tokens.css";
@value common: "../../styles/common.css";

@value flex_middle from common;
@value gray from tokens;


.setting {
  margin: 1em 0;
}

.setting_content {
  composes: flex_middle;
  justify-content: space-between;
}

.setting_comment {
  display: inline-block;
  margin-top: 0.5em;
  color: gray;
}