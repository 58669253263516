@value common: "../../styles/common.css";
@value tokens: '../../styles/tokens.css';
@value red from tokens;
@value box from common;

.container {
  composes: box;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message {
  text-align: center;
}

.errorMessage {
  color: red;
}

.input {
  margin-bottom: 12px;
}
