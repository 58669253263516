@value tokens: "../../styles/tokens.css";
@value common: "../../styles/common.css";

@value white, overlay, shadow, border-radius from tokens;
@value flex_middle from common;

.cover {
  background-color: overlay;
  overflow-x: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1;
}

.cover_transparent {
  background-color: rgba(0,0,0,0);
}

.modal {
  min-width: 430px;
  max-width: 800px;
  border-radius: border-radius;
  background-color: white;
}

.modal_shadow {
  box-shadow: shadow;
}

.secondary {
  width: auto !important;
  height: auto !important;
  min-width: 320px;
  max-width: 500px;
  padding: 0 1em;
}

.box {
  composes: flex_middle;
  height: 100%;
}

@media (max-width: 768px) { 
  .modal {
    height: 100%;
    width: 100%;
  }
}

.header {
  font-size: 1.1em;
  font-weight: bold;
}

.actions {
  display: flex;
  justify-content: flex-end;
}