.box {
  padding: 2em;
  margin: 0 auto;
  max-width: 1140px;
}

@media (max-width: 768px) { 
  .box {
    padding: 1em;
  }
}

.flex_middle {
  display: flex;
  align-items: center;
  justify-content: center;
}