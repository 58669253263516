@font-face {
  font-family: 'thisissand';
  src: url('./static/fonts/thisissand-regular-webfont.woff2') format('woff2'),
    url('./static/fonts/thisissand-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'thisissand';
  src: url('./static/fonts/thisissand-bold-webfont.woff2') format('woff2'),
    url('./static/fonts/thisissand-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'thisissandlogo';
  src: url('./static/fonts/thisissandlogo-regular-webfont.woff2')
      format('woff2'),
    url('./static/fonts/thisissandlogo-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  color: #303030;
  line-height: 1.4;
  min-width: 320px;
  position: relative;
  font-family: 'thisissand', Arial, Helvetica, sans-serif;
  font-size: 18px;
  background-color: #f4f4f4;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

a {
  color: #007DFF;
  text-decoration: none;
}

@media screen and (max-width: 576px) {
  body {
    font-size: 0.9em;
  }
}

canvas {
  display: block; /* remove scrollbars */
}

ul, ol {
  margin: 0;
  padding: 0;
}

.ol_roman {
  list-style-type: lower-roman;
}

.ol_latin {
  list-style-type: lower-latin;
  padding-left: 3em;
}


li {
  margin: 1.5em 0;
}

li div {
  vertical-align: top;
  display: inline-block;
  margin-left: 1em;
}

p {
  line-height: 1.4;
}

img {
  vertical-align: top;
}

textarea {
  resize: none;
}
