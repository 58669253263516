@value common: "../../styles/common.css";
@value box from common;

.container {
  composes: box;
  max-width: 600px;
}

.link {
  display: inline-block;
  text-decoration: none;
  font-weight: bold;
  margin: 1em 0;
}

.description {
  margin-bottom: 0;
}
