@value common: "../../styles/common.css";
@value tokens: "../../styles/tokens.css";

@value box, flex_middle from common;
@value white, transition from tokens;

.header {
  width: 100%;
  position: absolute;
  background-color: white;
  z-index: 1;

  transform: none;
  transition: transform transition;
  will-change: transform;
}

.header a {
  text-decoration: none;
}

.hidden {
  transform: translate(0px, -4rem);
}

.content {
  composes: box flex_middle;
  width: 100%;
  height: 4rem;
  padding: 0 2em;
  justify-content: space-between;

}

@media (max-width: 768px) {
  .content {
    height: 3rem;
  }

  .button {
    display: none;
  }
}

.secondary {
  position: relative;
  transform: none;
  transition: none;
}
