@value tokens: "../../styles/tokens.css";
@value transition from tokens;


.sandbox {
  position: relative;
  margin-bottom: 2em;
}

/* Render fallback canvas while Sandbox loading to prevent content jump */
.sandbox canvas {
  position: relative;
  height: 100vh;
  width: 100vw;
  opacity: 0;
  transition: opacity transition;
}

.sandbox_active canvas {
  opacity: 1;
}

.cover {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: url('../../static/bannerUncropped.jpg') 25% -15% no-repeat;
  background-size: cover;
  z-index: -1;
}

.cover_mobile {
  background-position: 50% 0;
}

.header {
  position: absolute;
  top: 4rem;
  left: 4rem;
  width: 175px;
  pointer-events: all;
  user-select: none;
  display: flex;
  justify-content: space-between;
}

.header img {
  cursor: pointer;
  transform: translate(-50%, -50%);
}

@media (max-width: 576px) {
  .header {
    height: 100px;
    padding: 1em 2em;
  }

  .icons {
    width: 150px;
  }
}
