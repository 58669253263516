@value tokens: "../../../../styles/tokens.css";
@value white, coolGray, overlay, green, shadow, border-radius-large from tokens;

.preview {
  display: flex;
  margin: 0 1em;
  background-color: lightgray;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: border-radius-large;
}

.preview_shadow {
  box-shadow: shadow;
}

.preview_hidden {
  visibility: hidden;
}

.header {
  display: none;
}

.close_icon {
  cursor: pointer !important;
}

.caption {
  word-break: break-all;
}

.options {
  display: flex;
  justify-content: space-between;
}

.options img {
  cursor: pointer;
}

.likes {
  margin: 1em 0;
}

.heart {
  font-size: 0.75em;
  padding-right: 0.25em;
}

.image {
  position: relative;
  z-index: 0;
}

.image::before {
  position: absolute;
  content: "";
  max-width: 100%;
  height: 100%;
  background: url("../../../../static/rings-spinner.svg") center center no-repeat;
  background-color: coolGray;
  top: 0;
  z-index: -1;
}

.image img {
  object-fit: contain;
  visibility: visible;
  vertical-align: middle;
  width: 100%;
}

.links > * {
  display: block;
  padding: 1em;
}

.links > *:not(:first-child) {
  border-top: 1px solid coolGray;
}

.hidden {
  visibility: hidden !important;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 300px;
  max-width: 400px;


  background-color: white;
  padding: 2em;
}

@media (max-width: 768px) { 
  .preview {
   margin: 0;
   flex-direction: column;
   background-color: white;
   width: 100%;
   height: 100%;
   border-radius: 0;
  }

  .header {
    display: block;
  }

  .image {
    text-align: center;
    max-width: 100%;
  }

  .image img {
    max-width: 100%;
    height: auto;
  }

  .content {
    min-width: 100%;
  }

  .caption {
    min-height: 100px;
  }
}

.spinner {
  position: absolute;
}