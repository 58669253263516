@value common: "../../styles/common.css";
@value flex_middle from common;

@value tokens: "../../styles/tokens.css";
@value black, gray from tokens;

.footer {
  composes: flex_middle;
  transform: translate(0, -25vh);
  flex-direction: column;
  justify-content: space-around;
  height: 50px;
  text-align: center;
  padding-bottom: 4em;
}

.footer_secondary {
  transform: translate(0, 0);
}

.copyright {
  color: gray;
  margin-bottom: 0.5em;
}

.policy a {
  color: black;
  text-decoration: none;
}