@value tokens: "../../styles/tokens.css";
@value black, white from tokens;

.logo {
  font-size: 1.1em;
  font-family: 'thisissandlogo';
  text-transform: uppercase;
  color: black;
  user-select: none;
}

.big {
  font-size: 2.5em;
  text-shadow: 0 0 2.5em rgba(0,0,0,1);
}

.light {
  color: white;
}
