@value tokens: "../../styles/tokens.css";
@value blue, red from tokens;

.link {
  cursor: pointer;
  color: blue;
  font-size: 1.1em;
  text-align: center;
  user-select: none;
  text-decoration: none;
}

.strong {
  font-weight: bold;
}

.warning {
  color: red;
}